/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409201710-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqTableOverridesApiClass {
 
   constructor() {}

  /**
   * @summary Add one or more overrides to a column in a materialized table.  Any existing overrides will be replaced.
   * @param {string} id - The Seeq ID for the table definition
   * @param {string} columnId - The column ID that the overrides are being applied to
   */
  public addOverridesToColumn(
    body: models.ColumnOverrideInputListV1,
    {
      id,
      columnId
    } : {
      id: string,
      columnId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(columnId)) {
      throw new Error("'columnId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/table-definitions/${encodeURIComponent(String(id))}/columns/${encodeURIComponent(String(columnId))}/overrides`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.TableDefinitionOutputV1>;
  }

  /**
   * @summary Delete overrides from a table definition
   * @param {string} id - The Seeq ID for the table definition
   * @param {string} columnId - The column ID that contains overrides
   * @param {string} [itemId] - Delete override for the specified item ID
   * @param {string} [datumId] - Delete override for the specified datum ID (requires item ID)
   */
  public deleteOverridesFromColumn(
    {
      id,
      columnId,
      itemId,
      datumId
    } : {
      id: string,
      columnId: string,
      itemId?: string,
      datumId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(columnId)) {
      throw new Error("'columnId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/table-definitions/${encodeURIComponent(String(id))}/columns/${encodeURIComponent(String(columnId))}/overrides`,

      params: omitBy({
        ['itemId']: itemId,
        ['datumId']: datumId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.TableDefinitionOutputV1>;
  }

}


export const sqTableOverridesApi = new sqTableOverridesApiClass();
